.title {
    color: #3E4966;
    font-weight: 600;
    font-size: 18px;
}

.styleItem {
  width: 64px;
  height: 64px;
  border-radius: 14px;
  border: 2px solid transparent;
  font-size: 20px;
  line-height: 64px;
  text-align: center;
  margin-right: 58px;
  cursor: pointer;
  color: #3E4966;
  transition: all 0.3s ease-in-out;
  background: transparent;
}
.styleItem:first-child {
  margin-bottom: 1px;
}
.styleItem:last-child {
  margin-right: 0;
  margin-bottom: 3px;
  font-size: 24px;
}
.styleItem:hover {
  background: rgb(62, 73, 102, 0.08);
  width: 64px;
  height: 64px;
}
.styleItemActive {
    border-color: #3E4966;
}
.styleItemActive:hover {
  background: transparent;
}

@media screen and (max-width: 375px), screen and (max-height: 725px) {

  .styleItem {
    width: 56px;
    height: 56px;
    font-size: 18px;
    line-height: 58px;
  }
  .styleItem:hover {
    width: 58px;
    height: 58px;
  }
  .styleItem:first-child {
    font-size: 16.5px;
  }
  .styleItem:nth-child(2) {
    font-size: 19px;
  }
  .styleItem:last-child {
    font-size: 21px;
  }
}
