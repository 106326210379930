.UnitsButton {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: #3E4966;
  background: transparent !important;
  /* border: 2px solid #E6E7EA; */
}

.UnitsButtonActive {
  /* border: 2px solid #fff; */
}

.UnitsButton svg {
  margin-top: 3px;
  width: 20px;
}

@media screen and (max-width: 390px), screen and (max-height: 901px) {
  .UnitsButton {
    font-size: 14px;
  }

  .UnitsButton svg {
    margin-top: 2px;
    width: 15px;
  }
}
