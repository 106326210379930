.TextInput {
    display: block;
    max-width: 100%;
    font-family: 'Averta', sans-serif;
    font-weight: 600;
    font-size: 21px;
    line-height: 24px;
    height: 64px;
    border-radius: 16px;
    padding-left: 24px;
    padding-right: 24px;
    color: #3E4966;
    position: relative;
    transition: all .3s ease-in-out;
    border: 2px solid #fff;
  }
  .TextInput::before {
    position: fixed;
    content: "Before ";
    font-weight: bold;
    color: blue;
    top: 0;
    left: 0;
    z-index: 99999;
  }
  
  .TextInput::placeholder {
    font-size: 21px;
    font-weight: 400;
    color: #7E8598;
  }
  .TextInput:focus {
    outline-style: solid;
    outline-color: rgba(3,49,66, 0.3);
    outline-width: 1px;
    /* border: 2px solid #4BBADB; */
    /* box-shadow: -1px 20px 30px 0px rgba(105,105,105,0.35); */
  }
  