@tailwind base;
@tailwind components;
@tailwind utilities;

/*
  Breakpoints:
  - width: 390px  // iPhone 12 Pro size
  - height: 901px
*/

/* Caveat */
@font-face {
  font-family: 'Caveat';
  src: url('../assets/fonts/pendant/Caveat/Caveat-Regular.ttf') format('truetype');
}

/* Albra */
@font-face {
    font-family: 'Albra';
    src: url('../assets/fonts/nameplate/Albra/albratext-bleck.ttf') format('truetype');
}

/* Averta */

@font-face {
    font-family: 'Averta';
    src: url('../assets/fonts/nameplate/Averta/AvertaStd-Extrathin.ttf') format('truetype');
    font-weight: 100;
}
@font-face {
    font-family: 'Averta';
    src: url('../assets/fonts/nameplate/Averta/AvertaStd-Thin.ttf') format('truetype');
    font-weight: 200;
}
@font-face {
    font-family: 'Averta';
    src: url('../assets/fonts/nameplate/Averta/AvertaStd-Light.ttf') format('truetype');
    font-weight: 400;
}
@font-face {
    font-family: 'Averta';
    src: url('../assets/fonts/nameplate/Averta/AvertaStd-Regular.ttf') format('truetype');
    font-weight: 400;
}
@font-face {
    font-family: 'Averta';
    src: url('../assets/fonts/nameplate/Averta/AvertaStd-Semibold.ttf') format('truetype');
    font-weight: 600;
}
@font-face {
    font-family: 'Averta';
    src: url('../assets/fonts/nameplate/Averta/AvertaStd-Bold.ttf') format('truetype');
    font-weight: 700;
}
@font-face {
    font-family: 'Averta';
    src: url('../assets/fonts/nameplate/Averta/AvertaStd-ExtraBold.ttf') format('truetype');
    font-weight: 800;
}
@font-face {
    font-family: 'Averta';
    src: url('../assets/fonts/nameplate/Averta/AvertaStd-Black.ttf') format('truetype');
    font-weight: 900;
}

/* Museo Sans Cyrl */
@font-face {
  font-family: 'Museo Sans Cyrl';
  src: url('../assets/fonts/pendant/MuseoSansCyrl/MuseoSansCyrl-300.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Museo Sans Cyrl';
  src: url('../assets/fonts/pendant/MuseoSansCyrl/MuseoSansCyrl-500.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Museo Sans Cyrl';
  src: url('../assets/fonts/pendant/MuseoSansCyrl/MuseoSansCyrl-700.ttf') format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'Museo Sans Cyrl';
  src: url('../assets/fonts/pendant/MuseoSansCyrl/MuseoSansCyrl-700.ttf') format('truetype');
  font-weight: 700;
}

/* Quicksand */
@font-face {
  font-family: 'Quicksand';
  src: url('../assets/fonts/pendant/Quicksand/Quicksand_Book.otf') format('opentype');
}

/* Anderlecht */
@font-face {
  font-family: 'Anderlecht';
  src: url('../assets/fonts/nameplate/Anderlecht/Anderlecht.ttf') format('truetype');
}

/* MuseoSansCyrl-900 */
@font-face {
  font-family: 'MuseoSansCyrl-900';
  src: url('../assets/fonts/nameplate/MuseoSansCyrl-900/MuseoSansCyrl-900.otf') format('opentype');
}

/* JockyStarline */
@font-face {
  font-family: 'JockyStarline';
  src: url('../assets/fonts/nameplate/JockyStarline/JockyStarline.ttf') format('truetype');
}

/* Ibarra Real Nova */
@font-face {
  font-family: 'Ibarra Real Nova';
  src: url('../assets/fonts/pendant/IbarraRealNova/IbarraRealNova-Regular.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Ibarra Real Nova';
  src: url('../assets/fonts/pendant/IbarraRealNova/IbarraRealNova-Bold.ttf') format('truetype');
  font-weight: bold;
}

/* Cyrene Regular Advanced */
@font-face {
  font-family: 'Cyrene Regular Advanced';
  src: url('../assets/fonts/pendant/CyreneRegularAdvanced/Cyrene-Regular.otf') format('opentype');
}

/* Source Code Pro */
@font-face {
  font-family: 'Source Code Pro';
  src: url('../assets/fonts/pendant/SourceCodePro/SourceCodePro-Regular.otf') format('opentype');
  font-weight: normal;
}
@font-face {
  font-family: 'Source Code Pro';
  src: url('../assets/fonts/pendant/SourceCodePro/SourceCodePro-Bold.otf') format('opentype');
  font-weight: bold;
}

/* Bayon Regular */
@font-face {
  font-family: 'Bayon Regular';
  src: url('../assets/fonts/pendant/Bayon/bayon.ttf') format('truetype');
}

/* Monk Gothic */
@font-face {
  font-family: 'Monk Gothic';
  src: url('../assets/fonts/pendant/MonkGothic/Monk-Gothic.ttf') format('truetype');
}

/* Lobster */
@font-face {
  font-family: 'Lobster';
  src: url('../assets/fonts/nameplate/Lobster/Lobster-Regular.ttf') format('truetype');
}

/*
  Reset
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

html {
    scroll-behavior: smooth;
}

body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*
  Common styles
*/
*,
*:before,
*:after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* tailwind remove blue bg on mobile devices */
}

body {
  font-family: 'Averta', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  touch-action: pan-x pan-y !important;
}

input:-webkit-autofill {
    -webkit-text-fill-color: #3E4966!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Scrollbar */

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #939393;
  border-radius: 10px;
}

html {
    height: 100%;
    overflow: auto
}
