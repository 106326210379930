.title {
    color: #3E4966;
    font-weight: 600;
    font-size: 18px;
}

.styleItem {
    width: 64px;
    height: 64px;
    border-radius: 14px;
    border: 2px solid transparent;
    font-size: 18px;
    font-weight: bold;
    line-height: 54px;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #3E4966;
    transition: all 0.3s ease-in-out;
    background: transparent;
}
.styleItem:last-child {
    margin-right: 0;
}
.styleItem:hover {
  background: rgb(62, 73, 102, 0.08);
  width: 64px;
  height: 64px;
}
.styleItemActive {
    border-color: #3E4966;
}
.styleItemActive:hover {
  background: transparent;
}
.styleItemMobile {
    margin-right: 30px;
}
.styleItemMobile:last-child {
    margin-right: 0;
}

.styleItemLabel {
    font-size: 16px;
    font-weight: 400;
    color: #3E4966;
    margin-top: 10px;
    margin-right: 30px;
}

.styleItemLabelActive {
    font-weight: 600;
}
