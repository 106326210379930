.modifyNamePlate {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 72px);
  height: 72px;
  background: #f3f3f6;
  backdrop-filter: blur(33px);
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  font-size: 21px;
  font-weight: bold;
  outline: none;
  border: none;
  position: relative;
}

.modifyNamePlate img {
  width: 40px;
  margin-right: 15px;
}

.modifyNamePlate:after {
  content: '';
  width: 100%;
  height: 15px;
  background: #f3f3f6;
  backdrop-filter: blur(33px);
  position: absolute;
  bottom: -10px;
}

.rightRoundedCorner {
  width: 20px !important;
  position: absolute;
  right: -35px;
  bottom: -4px;
  z-index: 10;
}

.smallNavButton img {
  width: 75%;
}

@media screen and (max-width: 390px), screen and (max-height: 901px) {
  .modifyNamePlate {
    height: 50px;
    width: calc(100% - 50px);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    font-size: 18px;
  }

  .modifyNamePlate img {
    width: 30px;
    margin-right: 7px;
  }

  .rightRoundedCorner {
    right: -27px;
  }
}
