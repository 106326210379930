.settingsEditor {
  height: 300px;
  background: #f3f3f6;
  backdrop-filter: blur(33px);
  border-top-right-radius: 32px;
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
}

.contentContainer {
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.desktopSettingsEditor {
  height: auto !important;
  padding-bottom: 20px !important;
  border-radius: 32px !important;
}

@media screen and (max-width: 390px), screen and (max-height: 901px) {
  .settingsEditor {
    border-top-right-radius: 15px;
    height: 248px;
  }
  .contentContainer {
    height: 125px;
    padding-top: 5px;
  }
  .desktopSettingsEditor {
    border-radius: 15px !important;
  }
}
