.menuTitle {
  width: 100%;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  padding-top: 30px;
  position: relative;
}

.goBackButton {
  cursor: pointer;
  width: 40px;
  position: absolute;
  left: 0;
  top: 25px;
}

@media screen and (max-width: 390px), screen and (max-height: 901px) {
  .menuTitle {
    font-size: 18px;
    line-height: 22px;
    padding-top: 20px;
  }

  .goBackButton {
    width: 30px;
    top: 16px;
  }
}

.selectedMaterial {
  color: #3E4966;
  font-weight: 600;
  font-size: 18px;
}

.materialItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: capitalize;
  margin-left: 62px;
  width: 60px;
}
.materialItem:first-child {
  margin-left: 0;
}

.materialItemImageContainer {
  padding: 2px;
  border: 2px solid transparent;
  border-radius: 15px;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
}
.materialItemImageContainer:hover {
  background: rgb(62, 73, 102, 0.08);
  width: 64px;
  height: 64px;
}

.materialItemTitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  margin-top: 10px;
  white-space: nowrap;
  color: #797F92;
}

.materialItemCarat {
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  margin-top: 4px;
  margin-bottom: 8px;
  white-space: nowrap;
  color: #797F92;
}

.materialItemActive .materialItemImageContainer {
  border-color: #3E4966;
}
.materialItemActive .materialItemImageContainer:hover {
  background: none;
}
.materialItemActive .materialItemTitle {
  font-weight: 600;
  color: #3E4966;
}

.materialItemActive .materialItemCarat {
  font-weight: 600;
  color: #3E4966;
}

.materialItemTitleActive {
  opacity: 1;
  font-weight: 600;
}

.materialItemTitleMobile {
  font-size: 16px !important;
}

@media screen and (max-width: 390px), screen and (max-height: 901px) {
  .materialItemImage {
    width: 75px;
    height: 75px;
    background-size: 100px 100px;
  }

  .materialItemTitle .materialItemCarat {
    font-size: 14px;
    line-height: 18px;
  }

  .materialItemActive {
    border-radius: 15px;
  }
}
