.menuTitle {
  width: 100%;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  padding-top: 30px;
  position: relative;
}

.goBackButton {
  cursor: pointer;
  width: 40px;
  position: absolute;
  left: 0;
  top: 25px;
}

@media screen and (max-width: 390px), screen and (max-height: 901px) {
  .menuTitle {
    font-size: 18px;
    line-height: 22px;
    padding-top: 20px;
  }

  .goBackButton {
    width: 30px;
    top: 16px;
  }
}

.selectedSize {
  color: #3E4966;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 5px;
  margin-top: 25px;
}
.selectedSizeInfo {
  font-size: 14px;
  font-weight: 400;
  color: #3E4966;
  margin-top: 20px;
}
.selectedSizeInfoMobile {
  font-size: 16px;
}

.sizeItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.sizeItemTitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  opacity: 0.4;
  position: relative;
  margin-top: 15px;
}

.sizeItemTitleActive {
  opacity: 1;
}

@media screen and (max-width: 390px), screen and (max-height: 901px) {
  .sizeItemTitle {
    font-size: 14px;
    line-height: 18px;
    margin-top: 10px;
  }
}
