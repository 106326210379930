.doneBtn {
  text-align: center;
  font-size: 21px;
  font-weight: 600;
  width: 100%;
  height: 64px;
  margin-bottom: 24px;
  border-radius: 14px;
  background: #fff;
  color: #3E4966;
  border: none;
}

@media screen and (max-width: 375px), screen and (max-height: 725px) {
  .doneBtn {
    height: 56px !important;
    border-radius: 14px;
    font-size: 18px;
  }
}
