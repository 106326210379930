@tailwind components;

@layer components {
    .desktop-line {
        @apply after:content-[''] after:absolute after:block after:border-[1px] after:border-solid after:border-terracotta after:right-[-41%] after:bottom-[22%] after:max-w-[75%] after:w-full;
    }

    .mobile-line {
        @apply after:content-[''] after:absolute after:block after:border-[1px] after:border-solid after:right-[50%] after:border-terracotta after:top-[140%] after:max-h-[250px] after:min-h-[130px] after:h-full;
    }
}
