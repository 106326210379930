.TextInput {
  display: block;
  /* max-width: 100%; */
  /* width: 314px; */
  width: 350px;
  font-family: 'Averta', sans-serif;
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  height: 64px;
  border-radius: 123px;
  padding-left: 24px;
  padding-right: 24px;
  color: #3E4966;
  position: relative;
  transition: all .3s ease-in-out;
  border: 2px solid #fff;
}
.TextInput::before {
  position: fixed;
  content: "Before ";
  font-weight: bold;
  color: blue;
  top: 0;
  left: 0;
  z-index: 99999;
}

.TextInput::placeholder {
  font-size: 21px;
  font-weight: 400;
  color: #7E8598;
}
.TextInput:focus {
  border: 2px solid #4BBADB;
  box-shadow: -1px 20px 30px 0px rgba(105,105,105,0.35);
}

.eraseTextBtn {
  width: 41px;
  height: 41px;
  background: #F0F0F3 !important;
  position: absolute;
  right: 12px;
  top: 28px;
}
.eraseTextBtn svg {
  width: 12px;
}
.eraseTextBtn svg path {
  stroke: #3E4966;
}
.eraseTextBtn:hover {
  width: 41px;
  height: 41px;
}

@media screen and (max-width: 375px), screen and (max-height: 725px) {
  .eraseTextBtn {
    right: 8px;
    width: 41px !important;
    height: 41px !important;
  }
  .eraseTextBtn svg {
    width: 15px !important;
  }
}

.checkmark {
  display: block;
  width: 48px;
  height: 48px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  right: 8px;
  top: 8px;
}

/*@media screen and (max-width: 390px), screen and (max-height: 901px) {*/
/*  .TextInput {*/
/*    font-size: 21px;*/
/*    line-height: 22px;*/
/*    height: 64px;*/
/*    border-radius: 16px;*/
/*    padding-left: 20px;*/
/*    padding-right: 20px;*/
/*    box-shadow: 10px 10px 85px -36px rgba(0,0,0,0.75);*/
/*  }*/

/*  .TextInput::placeholder {*/
/*    font-size: 21px;*/
/*    color: #7E8598;*/
/*  }*/

/*  .checkmark {*/
/*    width: 44px;*/
/*    height: 44px;*/
/*    right: 3.5px;*/
/*    top: 3.5px;*/
/*  }*/
/*}*/
