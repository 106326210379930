/*
    Default button
 */

.Btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  background: #fff;
  border-radius: 14px;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.Btn svg {
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

.Btn img,
.Btn svg {
  width: 26px;
  margin: 0;
  padding: 0;
}

.BtnMobile {
  width: 48px;
  height: 48px;
}

/*
    Transparent button
*/
.BtnTransparent {
  background: rgba(62,73,102, 0.08);
  backdrop-filter: blur(33px);
  transition: all 0.3s ease-in-out;
  border: none;
}
.BtnTransparent:hover {
  background: rgba(62,73,102, 0.1);
}

.BtnDisabled svg {
  opacity: 0.3;
}

/*
    Full content button
 */
.BtnFullContent img,
.BtnFullContent svg {
  width: 50%;
}

@media screen and (max-width: 390px), screen and (max-height: 901px) {
  .BtnFullContent img,
  .BtnFullContent svg {
    width: 55%;
  }
}

/*
  Active button
*/
.BtnActive {
  background: white !important;
}
