.Line {
  position: relative;
  height: 39px;
  width: 309px;
  background-size: 309px 39px;
  background-position: center;
  background-repeat: no-repeat;
}

.Range {
  display: inline-block;
  position: relative;
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
  margin-top: 3px;
}

.Line::before {
  content: ' ';
  display: block;
  width: 1px;
  height: 12px;
  background: #3E4966;
  position: absolute;
  top: 12px;
  left: 50%;
  opacity: 0.3;
}

@-moz-document url-prefix() {
  .Range {
    position: absolute;
    top: 50%;
  }
}

.withoutStrip::before {
  display: none;
}

.Range::-webkit-slider-thumb {
  -webkit-appearance: none;
}

.Range:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

.Range::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}

/*
    Styling the round button
*/

/* Special styling for WebKit/Blink */
.Range::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 36px;
  width: 36px;
  border-radius: 55px;
  background: #fff;
  cursor: pointer;
  box-shadow: 0px 11px 22px rgba(0, 0, 0, 0.11);
  margin-top: 27px;
  z-index: 50 !important;
}

/*!* All the same stuff for Firefox *!*/
.Range::-moz-range-thumb {
  box-shadow: 0px 11px 22px rgba(0, 0, 0, 0.11);
  height: 36px;
  width: 36px;
  border-radius: 55px;
  background: #ffffff;
  cursor: pointer;
}

/*!* All the same stuff for IE *!*/
.Range::-ms-thumb {
  box-shadow: 0px 11px 22px rgba(0, 0, 0, 0.11);
  height: 36px;
  width: 36px;
  border-radius: 55px;
  background: #ffffff;
  cursor: pointer;
}

/*
    Styling the track
 */

.Range {
  height: 39px;
  width: 309px;
  cursor: pointer;
  background-size: 309px 39px;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}
.Line::after {
  content: '';
  display: block;
  width: 309px;
  height: 2px;
  background-color: #3E4966;
  border-radius: 1px;
  position: absolute;
  bottom: -8px;
  z-index: -1;
}

.Range::-webkit-slider-runnable-track {
  height: 39px;
  width: 309px;
  cursor: pointer;
}

.Range::-moz-range-track {
  height: 39px;
  width: 309px;
  cursor: pointer;
}

.Range::-ms-track {
  height: 39px;
  width: 309px;
  cursor: pointer;
}
.Range::-ms-fill-lower {
  height: 39px;
  width: 309px;
  cursor: pointer;
}

.Range::-ms-fill-upper {
  height: 39px;
  width: 309px;
  cursor: pointer;
}

@media screen and (max-width: 390px), screen and (max-height: 901px) {
  /* Special styling for WebKit/Blink */
  .Range::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 36px;
    width: 36px;
    border-radius: 55px;
    background: #fff;
    cursor: pointer;
    margin-top: 27px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
    box-shadow: 0px 11px 22px rgba(0, 0, 0, 0.11);
  }

  /*!* All the same stuff for Firefox *!*/
  .Range::-moz-range-thumb {
    box-shadow: 0px 11px 22px rgba(0, 0, 0, 0.11);
    height: 36px;
    width: 36px;
    border-radius: 55px;
    background: #ffffff;
    cursor: pointer;
  }

  /*!* All the same stuff for IE *!*/
  .Range::-ms-thumb {
    box-shadow: 0px 11px 22px rgba(0, 0, 0, 0.11);
    height: 36px;
    width: 36px;
    border-radius: 55px;
    background: #ffffff;
    cursor: pointer;
  }

  /*
      Styling the track
   */

  .Range {
    height: 39px;
    width: 309px;
    cursor: pointer;
    background-size: 309px 39px;
    background-position: center;
    background-repeat: no-repeat;
  }

  .Range::-webkit-slider-runnable-track {
    height: 39px;
    width: 309px;
    cursor: pointer;
  }

  .Range::-moz-range-track {
    height: 39px;
    width: 309px;
    cursor: pointer;
  }

  .Range::-ms-track {
    height: 39px;
    width: 309px;
    cursor: pointer;
  }
  .Range::-ms-fill-lower {
    height: 39px;
    width: 309px;
    cursor: pointer;
  }

  .Range::-ms-fill-upper {
    height: 39px;
    width: 309px;
    cursor: pointer;
  }
}
